<template>
  <div class="container">
    <h1>{{ $t('legal.title') }}</h1>
    <!-- <p>
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium. Totam rem aperiam, eaque ipsa quae ab
      illo inventore veritatis et quasi architecto beatae vitae dicta sunt.
    </p> -->
    <!-- <h2>Legal Links</h2> -->
    <div class="button-container">
      <PassportButton :label="$t('legal.terms')" @click="viewPolicy('terms')" class="button" />
      <PassportButton :label="$t('legal.privacy')" @click="viewPolicy('privacy')" class="button" />
      <PassportButton :label="$t('legal.cookies')" @click="viewPolicy('cookie')" class="button" />
      <!-- <PassportButton :label="$t('legal.emailConsent.label')" @click="viewPolicy('email')" class="button" /> -->
    </div>
    <div v-if="showEmailCopy" class="modal-wrap">
      <div class="modal">
        <div class="modal-body">
          <XIcon class="x-icon" @click="showEmailCopy = false" />
          <LegalCopy :lines="$t('legal.emailConsent.lines')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';
import XIcon from '@/assets/icons/x_icon.svg';
import LegalCopy from '@/components/LegalCopy.vue';

export default {
  name: 'Login',
  components: {
    PassportButton,
    XIcon,
    LegalCopy,
  },
  data() {
    return {
      showNav: false,
      showEmailCopy: false,
    };
  },
  mounted() {},
  methods: {
    viewPolicy(policy) {
      if (policy === 'privacy') {
        window.open(this.$t('legal.privacyLink'), '_blank');
      } else if (policy === 'terms') {
        window.open(this.$t('legal.termsLink'), '_blank');
      } else if (policy === 'cookie') {
        window.open(this.$t('legal.cookiesLink'), '_blank');
      } else if (policy === 'email') {
        this.showEmailCopy = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  h1 {
    margin-bottom: 30px;
  }
  p {
    margin: 0;
  }
  h2 {
    margin-top: 64px;
    margin-bottom: 31px;
  }
}
.paragraph {
  padding: 25px;
  font-size: 18px;
  text-align: left;
}
.button {
  margin-bottom: 18px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // width: 315px;
  // height: 53px;
  // margin-top: 1em;
  // font-family: $head-font-stack;
  // font-size: 16px;
  // text-transform: uppercase;
  // background-color: $primary-white;
  // @include bp-sm-phone-landscape {
  //   width: 300px;
  // }
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  display: block;
  width: 2.5em;
  height: 1.2em;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
}

.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  min-height: calc(100vh - 73px);
  background: rgba(36, 58, 83, 0.8);
  backdrop-filter: blur(5px);
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    max-width: 291px;
    max-height: 90%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    // max-height: 479.406px;
    // .modal-content {
    //   overflow-y: scroll;
    // }
    .modal-body {
      position: relative;
      padding: 32px;
      color: #fff;
      background-color: #243a53;
    }
  }
}
.x-icon {
  position: fixed;
  top: 27px;
  right: 27px;
  width: 20px;
}
</style>
